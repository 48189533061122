import Service from '../Service';

const resource = "orderRequest/"

export default {
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID },
        });
    },

    save(obj, requestID) {
        return Service.post(resource + 'save', obj, {
            params: { requestID }
        });
    },

    getDetail(obj, requestID) {
        return Service.post(resource + 'GetDetail', obj, {
            params: { requestID }
        });
    },

    orderrequestinprocess(requestID) {
        return Service.post(resource + 'orderrequestinprocess', obj, {
            params: { requestID }
        });
    }

}