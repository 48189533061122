<template>
	<div>
		<v-container>
			<s-crud
				:filter="filter"
				:config="config"
				add
				edit
				remove
				@save="save($event)"
				title="Solicitud de pedido Calibrado"
				height="auto"
				@addEvent="addEvent()"
				@rowSelected="rowSelected($event)"
				ref="refreshcrudorder"
			>
				<template v-slot:filter>
					<v-container>
						<v-row style="margin-bottom: 15px" justify="center">
							<v-col cols="6" lg="3">
								<s-date
									label="Fecha Inicio"
									v-model="filter.BeginDate"
								></s-date>
							</v-col>

							<v-col cols="6" lg="3">
								<s-date
									label="Fecha Fin"
									v-model="filter.EndDate"
								>
								</s-date>
							</v-col>
						</v-row>
					</v-container>
				</template>

				<template scope="props">
					<v-container>
						<v-row>
							<v-col cols="12">
								<v-row justify="center">
									<v-col cols="6"  lg="2" md="4" class="pb-0 pt-1">
										<s-date
											label="Fecha"
											v-model="props.item.OrcDate"
										></s-date>
									</v-col>

									<v-col cols="6"  lg="2" md="4" class="pb-0 pt-1">
										<s-select-definition
											:def="1281"
											:dgr="39"
											label="Area"
											v-model="props.item.TypeArea"
										/>
									</v-col>

									<v-col cols="6"  lg="2" md="4" class="pb-0 pt-1">
										<s-select-definition
											clearable
											
											:def="1173"
											label="Cultivo"
											v-model="props.item.TypeCultive"
										/>
									</v-col>

									<v-col cols="6"  lg="2" md="4" class="pb-0 pt-1">
										<s-select-variety
											clearable
											:cultiveID="props.item.TypeCultive"
											label="Variedad"
											full
											v-model="props.item.VrtID"
										/>
									</v-col>
									<v-col cols="6"  lg="2" md="4" class="pb-0 pt-1">
										<s-select-definition
											label="Calibre"
											clearable
											:dgr="34"
											:def="1266"
											v-model="props.item.TypeCaliber"
										>
										</s-select-definition>
									</v-col>

									<v-col cols="6"  lg="2" md="4" class="pb-0 pt-1">
										<s-text
											number
											v-model="props.item.OrcAmountJaba"
											label="Cant. Jabas"
											:min="0"
										></s-text>
									</v-col>
								</v-row>
							</v-col>

							<v-col lg="4" md="4" cols="12">
								<v-card outlined>
									<v-col cols="12">
										<v-row class="mt-3">
											<v-col class="pb-0 pt-0">
												<h2>Certificaciones</h2>
											</v-col>
											<v-spacer></v-spacer>
											<v-btn
												class="mr-3 elevation-5"
												x-small
												fab
												color="warning"
												@click="addItemCertification()"
											>
												<i class="fas fa-plus"></i>
											</v-btn>
										</v-row>
										<v-col class="pl-0 pr-0"
											><v-divider></v-divider>
										</v-col>
										<v-row justify="center">
											<v-col>
												<!-- <s-select-definition
													:def="1159"
													label="Certificación"
													return-object
													v-model="objCertification"
												/> -->
												<s-select-type-certification
													label="Certificación"
													full
													return-object
													v-model="objCertification"
												>
												</s-select-type-certification>
											</v-col>
										</v-row>
									</v-col>
								</v-card>
							</v-col>

							<v-col lg="8" md="8" cols="12">
								<v-card outlined>
									<v-col cols="12">

									
											<v-data-table
												:items="itemCertifications"
												:headers="headersCertifications"
												hide-default-footer
												dense
												item-key="Line"
												:items-per-page="-1"
												disable-sort
											>
												<template v-slot:item.accion="{ item }">
												<v-btn block color="error" @click="removeItem(item)" x-small>
													<v-icon>mdi-close</v-icon></v-btn
												>
												</template>

												<!--  -->
											</v-data-table>
							
										
									
								
									</v-col>
								</v-card>
							</v-col>
						</v-row>
					</v-container>
				</template>

				<!-- <template v-slot:OrcStatus="{ row }">
                  <v-chip x-small :color="row.OrcStatus == 2 ? 'info' : 'warning'">
                    {{row.OrcStatus == 2 ? 'Pedido Cumplido' : 'En Proceso'}}
                  </v-chip>
                </template> -->
				<template v-slot:OrcInProcess="{ row }">
                  <v-chip x-small :color="row.OrcInProcess == 1 ? 'info' : 'error'">
                    {{row.OrcInProcess == 1 ? 'Iniciado' : 'Sin iniciar'}}
                  </v-chip>
                </template> 
				

				<template v-slot:OrcInitial="{ row }">
					<v-btn
						v-if="row.OrcInProcess != 1"
						x-small
						:color="'info'"
						@click="initialOrder(row)"
					>
					Iniciar
					</v-btn>
                </template>

				
			</s-crud>
		</v-container>
	</div>
</template>

<script>
	import _sOrderRequestCalibratedService from "@/services/FreshProduction/OrderRequestCalibratedService";
	import SSelectVariety from "@/components/HarvestSchedule/SSelectVariety";
	import SSelectTypeCertification from "@/components/Utils/Certification/sSelectTypeCertification.vue";

	export default {
		components: { SSelectVariety, SSelectTypeCertification },
		data() {
			return {
				filter: { BeginDate: null, EndDate: null, TypeCertification: "" },
				config: {
					model: {
						OrcID: "ID",
						OrcDate: "date",
						OrcStatus: "",
						OrcInitial: "",
						OrcInProcess: ""
					},
					service: _sOrderRequestCalibratedService,
					headers: [
						{ text: "Accion", value: "OrcInitial" },
						{ text: "ID", value: "OrcID" },
						{ text: "Fecha", value: "OrcDate" },
						{ text: "Area", value: "TypeAreaName" },
						{ text: "Cultivo", value: "TypeCultiveName" },
						{ text: "Variedad", value: "VrtName" },
						{ text: "Calibre", value: "TypeCaliberName" },
						{ text: "Cantidad de jabas", value: "OrcAmountJaba" },
						{ text: "Estado", value: "OrcInProcess" },
					],
				},

				TypeCultive: 0,
				VrtID: 0,
				itemCertifications: [],
				headersCertifications: [
					{text: 'Certificación', value: 'TcDescription'},
					{text: 'Acción', value: 'accion', width: 100},
				],
				itemRemoved: [],
				objCertification: null
			};
		},
		methods: {

			rowSelected(item){
				
				if(item !== undefined){
					if(item.length > 0){
						this.OrcID = item[0].OrcID;
						this.getDetail()
					}
				}
			},

			addEvent(){
				this.itemCertifications = []
			},

			orderDetails() {
				let i = 0;
				this.itemCertifications.map(e => {
					i++;
					e.Line = i;
				});
			},

			getDetail() {
				this.itemCertifications = []
				_sOrderRequestCalibratedService
					.getDetail({OrcID: this.OrcID}, this.$fun.getUserID())
					.then(r => {
						
						 if(r.status == 200){
							this.itemCertifications = r.data;
							this.orderDetails()
						}
						
					});

				
				
			},


			addItemCertification()
			{
					console.log("item", this.objCertification);

					if(this.objCertification == null){
						this.$fun.alert("Seleccione Certificación", "warning")
						return
					}

					let exist = false
						this.itemCertifications.forEach(element => {
							console.log(element);
							if(element.TcID == this.objCertification.TcID){
								this.$fun.alert("Certificación ya esta agregada", "warning")
								exist = true
							}
						});
					

					if(exist == false){
						
						let detail = {
							OrdID: 0,
							TcID: this.objCertification.TcID,
							//TcID: this.objCertification.TcID,
							TcDescription: this.objCertification.TcDescription,
							SecStatus: 1,
							UsrCreateID: this.$fun.getUserID(),
						}
						this.itemCertifications.push(detail);

						this.orderDetails()
					}

					
			},

			removeItem(item) {
				
				if (this.itemCertifications.length > 0) {
					if (item.OrdID > 0) {
						this.itemRemoved.push(
							this.itemCertifications.filter(x => x.Line == item.Line)[0],
						);
						this.itemRemoved.forEach(element => {
							element.SecStatus = 0;
						});
					}

					this.itemCertifications = this.itemCertifications.filter(
						x => x.Line != item.Line,
					);
					this.orderDetails();
				}
			},

			save(item) {

				if(item.OrcStatus == 2){
					this.$fun.alert("Error, no se puede modificar registro.", "warning")
					return;
				}


				item.SecStatus = 1
				item.UsrCreateID = this.$fun.getUserID()
				item.UsrUpdateID = this.$fun.getUserID()

				if(item.TypeCultive == null){
					this.$fun.alert("Seleccione Cultivo", "warning")
					return
				}
				if(item.VrtID == null){
					this.$fun.alert("Seleccione Variedad.", "warning")
					return
				}

				if(item.TypeCaliber == null){
					this.$fun.alert("Seleccione Calibre.", "warning")
					return
				}

				if(item.OrcAmountJaba <= 0){
					this.$fun.alert("Ingrese cantidad de Jabas.", "warning")
					return
				}


				if(this.itemCertifications.length == 0) {
					this.$fun.alert("Agregue detalle de Certificacion.", "warning")
					return
				}

				item.itemsDetails = [...this.itemCertifications, ...this.itemRemoved]
				console.log("Guardar ", item);
				item.save();
			},

			initialOrder(item){
				console.log("fffff",item);

				item.OrcInProcess = 1
				item.UsrUpdateID = this.$fun.getUserID()

				this.$fun.alert("¿Seguro de Iniciar Pedido ?", "question").then(val => {
					if(val.value){
						_sOrderRequestCalibratedService.save(item, this.$fun.getUserID()).then(resp => {
							if(resp.status == 200){
								this.$fun.alert("Iniciado correctamente", "success")
								this.$refs.refreshcrudorder.refresh()
							}
						})
					}
				})
			}
		},
	};
</script>